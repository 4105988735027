*,
html {
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  background-color: #f8f8f8;
}

@media screen and (max-width: 1536px) {
  *,
  html {
    font-size: 11px;
  }
}
